export const handleColor = (index: number) => {
  const DEFAULT_COLOR = '#00A1D6'
  const COLORS = ['#182741', '#f8cd5c', '#7eccc6', '#565656', '#988E8E', '#F06140', '#EE3F2E'];

  return COLORS[index] || DEFAULT_COLOR
};

export const formatChartData = (data: Array<any>, datasets: number) => {
  const chartData = { labels: [], values: [] };

  data.map((row, index) => {
    const values = [];

    if (index) {
      row.cells.map((cell, i) => {
        const isCellLabel = index && !i;
        isCellLabel ? chartData.labels.push(cell) : values.push(cell)
      });
      chartData.values.push(values);
    } else {
      chartData.legend = datasets > 1 && row.cells.slice(1)
    }
  });

  return chartData;
};

export const createPolygon = (size: number, start: number, width: number) => {
  return `polygon(
    0% ${start}%, 
    100% ${size}%, 
    100% calc(${size}% + ${width}px), 
    0% calc(${start}% + ${width}px)
  )`
};

export const createBlogUrl = (slug: string, prefix: string) => {
  if (slug === 'all') return '/blog'
  
  return `/blog/${prefix}/${slug}`
};

export const createMasterCollection = (isAuthorPage: boolean) => {
  if (isAuthorPage) {
    return { slug: { current: 'all' }, name: 'Wszystkie posty' }
  }
  
  return { slug: { pl: { current: 'all' }}, title: { pl: 'Wszystko' }}
};

export const formatDate = (date: string) => {
  const months = ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Paź', 'Lis', 'Gru'];
  const dateArray = date.split('-');
  const year = dateArray[0];
  const month = months[parseInt(dateArray[1])];
  const day = dateArray[2];

  return `${month} ${day}, ${year}`;
};
