import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { sanityConfig } from "@utils/variables";

import BlockContent from "@sanity/block-content-to-react";

import Case from "@components/blog/post/content/case";
import Chart from "@components/blog/post/content/chart";
import Download from "@components/blog/post/content/download";
import Post from "@components/blog/post/content/post";
import Table from "@components/blog/post/content/table";
import Youtube from "@components/blog/post/content/youtube";

const serializers = {
  types: {
    block: props => {
      const { style = 'normal' } = props.node;

      if (style === 'normal') return <Text>{props.children}</Text>;
      if (style === 'h3') return <Header>{props.children}</Header>;
      if (style === 'blockquote') return <Quote><Line/>{props.children}</Quote>;
      if (style === 'figcaption') return <Figcaption>{props.children}</Figcaption>;

      return BlockContent.defaultSerializers.types.block(props);
    },
    chart: props => {
      const data = props.node;
      const params = { 
        description: { x: data.xDesc, y: data.yDesc },
        data: { scale: data.yScale, graduation: data.yGrad, lines: data.yLines }
      };
      return <Chart type={data.type} data={data.content.rows} params={params} />
    },
    download: props => {
      const data = props.node;
      return <Download small={data.small} big={data.big} image={data.image.asset._ref} url={data.url}/>
    },
    featuredCase: props => <Case id={props.node._ref} />,
    featuredPost: props => <Post id={props.node._ref} />,
    tables: props => <Table data={props.node}/>,
    youtube: props => <Youtube cover={props.node.cover} id={props.node.id} />
  },
  marks: {
    span: props => <span>{props.children}</span>
  },
  list: props => <List>{props.children}</List>,
  listItem: props => <ListItem><Dot/>{props.children}</ListItem>,
};

const Wrapper = tw(motion.div)`
  relative text-base text-second 
  sm:ml-20 
  md:ml-0 
  lg:w-4/5 lg:mx-auto lg:pl-50 lg:text-md lg:leading-35
  xl:w-2/3
`;

const Header = tw.h3`ml-40 mr-20 text-xl my-25 sm:mx-0 lg:text-4xl lg:leading-55`;

const Text = tw.p`my-15 ml-40 mr-20 sm:mx-0`;

const List = tw.ul`my-25 ml-40 mr-20 sm:mx-0`;

const ListItem = tw.li`relative py-10 pl-30 sm:pl-50`;

const Dot = tw.span`absolute top-20 left-0 w-8 h-8 rounded-full bg-portfolioText lg:top-24`;

const Quote = tw.blockquote`relative my-40 ml-40 mr-20 pl-30 sm:mx-0 sm:pl-50 lg:leading-27`;

const Line = tw.span`absolute top-0 left-0 h-full w-4 rounded-sm bg-portfolioText`;

const Figcaption = styled.figcaption`
  ${tw`text-center -mt-10 mb-25 lg:-mt-20 lg:mb-40`}

  a {
    ${tw`text-orange underline`}
  }
`;

const Content = ({ width, text }: { width: Number, text: any }) => {
  return (
    <Wrapper
      initial={ width >= 1024 && { opacity: 0, scaleX: 0 }}
      animate={ width >= 1024 && { opacity: 1, scaleX: 1 } }
      transition={{ opacity: { duration: 1, delay: 1.8, ease: 'linear' } }}
    >
      <BlockContent blocks={text} serializers={serializers} {...sanityConfig} />
    </Wrapper>
  );
};

export default Content;
