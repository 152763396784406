import React, { Fragment } from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";

import Item from "@components/blog/item";

const Wrapper = tw.div`relative py-20 sm:px-0 lg:pt-40 pb-20`;

const Header = tw.div`
  max-w-400 mx-auto px-20 text-center text-xl -tracking-016 
  lg:max-w-700 lg:mb-40 lg:text-4xl lg:-tracking-028
`;

const Text = tw.p`inline pb-5 text-orange`;

const Posts = tw.div`relative pt-40 sm:flex md:left-15 lg:py-40`;

const Featured = ({ id, data }: { id: string, data: any }) => {
  const { allSanityPost } = useStaticQuery(graphql`
    query {
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          _id
          publishedAt
          previewImage { asset { fluid { ...GatsbySanityImageFluid }}}
          slug { pl { current }}
          title { _rawPl }
          preview { _rawPl }
        }
      }
    }
  `);
  const featuredPosts = data.length ? data : allSanityPost.nodes.filter(post => post._id !== id).slice(0, 3);

  return (
    <Wrapper>
      <Header>
        Dajemy słowo, te artykuły również mogą być
        <Text> dla Ciebie interesujące</Text>
      </Header>
      <Posts>
        {featuredPosts.map((post, index) => (
          <Fragment key={index}>
            <Item data={post}/>
          </Fragment>
        ))}
      </Posts>
    </Wrapper>
  );
};

export default Featured;
