import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";

import ButtonOval from "@components/buttons/oval";
import CustomLink from "@components/link";

const Background = styled(BackgroundImage)`
  ${tw`
    flex h-240 my-25 pl-40 pr-20 text-white bg-center bg-no-repeat bg-cover 
    sm:rounded-lg
    lg:items-center lg:my-40 lg:px-50
  `}
  
  &::before {
    ${tw`rounded-none sm:rounded-lg`}
  }
`;

const Description = tw.div`mb-8 text-sm md:mb-20 md:text-base lg:relative`;

const Name = tw(CustomLink)`block mb-25 text-2xl -tracking-024 md:mb-10 md:text-4xl`;

const Project = tw.div`
  flex flex-col justify-end py-20 
  md:flex-row md:items-center md:justify-between md:min-w-1/2 md:pt-25
`;

const ProjectDetails = tw.div`flex flex-col justify-end md:mr-30`;

const Case = ({ id }: { id: String }) => {
  const { t } = useTranslation();
  const { allSanityCases } = useStaticQuery(graphql`
  query {
    allSanityCases {
      edges {
        node {
          id
          image { asset { fluid { ...GatsbySanityImageFluid }}}
          title
          slug { current }
        }
      }
    }
  }`);
  const featured = allSanityCases.edges.find(edge => edge.node.id === id).node;
  const url = `/portfolio/${featured.slug.current}`;

  return (
    <Background Tag="div" fluid={featured.image.asset.fluid}>
      <Project>
        <ProjectDetails>
          <Description>
            {t(`Blog:Case`)}
          </Description>
          <Name to={url} type='noIntl'>
            {featured.title}
          </Name>
        </ProjectDetails>
        <ButtonOval href={url} type='noIntl' />
      </Project>
    </Background>
  );
};

export default Case;
