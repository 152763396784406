import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const DescriptionX = tw.p`
  block absolute bottom-15 left-1/2 transform -translate-x-1/2 text-sm 
  sm:bottom-24
  lg:bottom-35 lg:text-base
`;

const DescriptionY = styled.p<{ moved: boolean }>`
  ${tw`block absolute top-1/2 transform -translate-y-1/2 -rotate-90 text-sm lg:text-base`}

  ${({ moved }) => moved
    ? tw`-left-15 sm:left-0 lg:left-15` 
    : tw`-left-40 sm:-left-25 lg:-left-10`
  };
`;

const ChartDescription = ({ multiline, data }: { multiline: boolean, data: object }) => (
  <>
    { data.x && <DescriptionX>{ data.x }</DescriptionX> }
    { data.y && <DescriptionY moved={multiline}>{ data.y }</DescriptionY> }
  </>
);

export default ChartDescription;
