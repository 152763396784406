import React from "react";
import tw from "twin.macro";

import { formatChartData } from "@utils/blogHelpers";

import ChartColumn from "@components/blog/post/content/chart/column";
import ChartDescription from "@components/blog/post/content/chart/description";
import ChartGrid from "@components/blog/post/content/chart/grid";
import ChartLabels from "@components/blog/post/content/chart/labels";
import ChartLegend from "@components/blog/post/content/chart/legend";
import ChartLine from "@components/blog/post/content/chart/line";

const ChartWrapper = tw.div`
  relative my-25 p-30 pb-50 border border-border
  sm:pr-10 sm:px-50 sm:pb-70 sm:rounded-lg
  lg:my-40 lg:px-100 lg:pt-50 lg:pb-100
`;

const TableWrapper = tw.div`relative`;

const Table = tw.table`box-border relative w-full h-150 mx-auto border-collapse bg-transparent md:h-300`;

const Chart = ({ type, data, params }: { type: string, data: any, params: object }) => {
  const datasets = data[0].cells.length - 1;
  const chart = formatChartData(data, datasets);
  const props = {
    isColumn: type === 'column',
    isLine: type === 'line',
    isStacked: datasets > 1,
    hasLegend: chart.legend.length > 1,
    hasGrid: params.data.lines,
    hasLabels: chart.labels
  };

  return (
    <ChartWrapper>
      { props.hasLegend && <ChartLegend data={chart.legend} /> }
      <TableWrapper>
        <Table>
          { props.isColumn && <ChartColumn data={chart} params={params.data} stacked={props.isStacked} /> }
          { props.isLine && <ChartLine data={chart} params={params.data} /> }
        </Table>
        { props.hasGrid && <ChartGrid scale={params.data.scale} grad={params.data.graduation} /> }
      </TableWrapper>
      { props.hasLabels && <ChartLabels data={chart.labels} />}
      <ChartDescription multiline={props.isLine && props.isStacked} data={params.description} />
    </ChartWrapper>
  );
};

export default Chart;
