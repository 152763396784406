import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { useViewportScroll } from 'framer-motion';
import { useIntl } from 'gatsby-plugin-intl';

import useWindowSize from '@utils/useWindowSize';

import { portableToPlain } from "@utils/helpers";

import Layout from '@layouts/index';
import SEO from '@components/seo';
import Collections from '@components/blog/collections';
import Header from '@components/blog/post/header';
import Intro from '@components/blog/post/intro';
import Author from '@components/blog/post/author';
import Image from '@components/blog/post/image';
import Content from '@components/blog/post/content';
import Featured from '@components/blog/post/featured';
import Offer from '@components/blog/post/offer';

const BlogPost = ({ pageContext }: any) => {
  const intl = useIntl();
  const { width } = useWindowSize();
  const { scrollY } = useViewportScroll();
  const { type, id } = pageContext;

  const { allSanityPost } = useStaticQuery(graphql`
    query {
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          _id
          publishedAt
          slug { pl { current }}
          categories { title { pl }, slug { pl { current }}}
          title { _rawPl }
          intro { _rawPl }
          author {
            name, 
            slug { current }, 
            image { asset { fluid { ...GatsbySanityImageFluid }}}
          }
          mainImage { asset { fluid { ...GatsbySanityImageFluid }}}
          text {_rawPl }
          other { 
            previewImage { asset { fluid { ...GatsbySanityImageFluid }}}
            slug { pl { current }}
            title { _rawPl }
            preview { _rawPl }
          }
        }
      }
    }
  `);
  const data = allSanityPost.nodes.filter(post => post._id === id);

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={portableToPlain(data[0].title._rawPl)}
        description={portableToPlain(data[0].intro._rawPl)}
      />
      <Collections width={width} type={type} categories={data[0].categories} active='none' />
      <Header width={width} title={data[0].title._rawPl} />
      <Intro width={width} intro={data[0].intro._rawPl} />
      <Author width={width} author={data[0].author} date={data[0].publishedAt} />
      <Image width={width} image={data[0].mainImage} />
      <Content width={width} text={data[0].text._rawPl} />
      <Featured width={width} id={data[0]._id} data={data[0].other} />
      <Offer width={width} scroll={scrollY} />
    </Layout>
  );
};

export default BlogPost;
