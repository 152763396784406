import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";

import { createMasterCollection } from "@utils/blogHelpers";

import Collection from "@components/blog/collection";

type CollectionsTypes = {
  width: Number,
  type: string,
  active: string,
  categories: any
};

const Background = tw.div`
  relative
  sm:-left-400 sm:min-w-1450
  md:-left-575 md:min-w-1920 
  lg:-left-450
  xl:-left-320
`;

const Gradient = tw(motion.div)`
  absolute top-0 left-1/2 transform -translate-x-1/2 w-screen h-600 
  bg-gradient-to-b from-lightBlue to-transparent
`;

const Wrapper = styled.div`
  ${tw`container relative z-2 mx-auto overflow-x-scroll overflow-y-hidden xxl:max-w-1280`}
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Line = styled.div`
  ${tw`flex ml-10 sm:-ml-10 md:-ml-30`}
  white-space: nowrap;
`;

const Collections = ({ width, type, active, categories }: CollectionsTypes) => {
  const [isDataReady, setIsDataReady] = useState(false);
  const { allSanityCategory, allSanityAuthor } = useStaticQuery(graphql`
    query {
      allSanityCategory(sort: { fields: order, order: ASC }) {
        nodes {
          _key,
          order,
          slug { pl { current }}
          title { pl }
        }
      }
      allSanityAuthor(sort: { fields: order, order: ASC }) {
        nodes {
          _key,
          order,
          slug { current }
          name
        }
      }
    }
  `);
  const pagesData = {
    post: categories,
    author: allSanityAuthor.nodes,
    blog: allSanityCategory.nodes,
    collection: allSanityCategory.nodes
  };
  const data = pagesData[type];
  const isAuthorPage = type === 'author';

  useEffect(() => {
    const firstSlug = isAuthorPage ? data[0].slug.current : data[0].slug.pl.current;
    firstSlug !== 'all' && data.unshift(createMasterCollection(isAuthorPage));
    setIsDataReady(true);
  }, [])
  
  return (
    <>
      {isDataReady && (
        <Background>
          <Gradient
            initial={width >= 1024 && 'hidden'} 
            animate={width >= 1024 && 'visible'} 
            transition={{ opacity: { duration: .3, delay: 1.2, ease: [.88, .14, .12, .86] }}}
            variants={{ 'visible': { opacity: 1 }, 'hidden': { opacity: 0 } }}
          />
          <Wrapper>
            <Line>
              {data.map((item, index) => {
                return (
                  <Collection 
                    key={index} 
                    width={width}
                    index={index}
                    isAuthorPage={isAuthorPage}
                    item={item}
                    active={active}
                  />
                )
              })}
            </Line>
          </Wrapper>
        </Background>
      )}
    </>
  );
};

export default Collections;
