import React from "react";
import Img from "gatsby-image";
import { motion } from "framer-motion";
import tw from "twin.macro";

const Wrapper = tw(motion.div)`relative`;

const MainImage = tw(Img)`absolute top-0 left-1/2 transform -translate-x-1/2 w-screen max-w-1600 mb-35`;

const Image = ({ width, image }: { width: Number, image: any }) => {
  return (
    <Wrapper
      initial={ width >= 1024 && { opacity: 0, scaleX: 0 }}
      animate={ width >= 1024 && { opacity: 1, scaleX: 1 } }
      transition={{ opacity: { duration: 1, delay: 1.8, ease: 'linear' } }}
    >
      <MainImage fluid={image.asset.fluid} />
    </Wrapper>
  );
};

export default Image;
