import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import tw from "twin.macro";

import ButtonOval from "@components/buttons/oval";
import CustomLink from "@components/link";

const Background = styled(BackgroundImage)`
  ${tw`
    flex h-240 my-25 pl-40 pr-20 text-white bg-center bg-no-repeat bg-cover 
    sm:rounded-lg
    lg:items-center lg:my-40 lg:px-50
  `}
  
  &::before {
    ${tw`rounded-none sm:rounded-lg`}
  }
`;

const Description = tw.div`mb-8 text-sm md:mb-20 md:text-base lg:relative`;

const Name = tw(CustomLink)`block mb-25 text-2xl -tracking-024 md:mb-10 md:text-4xl`;

const Project = tw.div`
  flex flex-col justify-end py-20 
  md:flex-row md:items-center md:justify-between md:min-w-1/2 md:pt-25
`;

const ProjectDetails = tw.div`flex flex-col justify-end md:mr-30`;

const Download = ({ small, big, image, url }: { small: string, big: string, image: string, url: string }) => {
  const { allSanityImageAsset } = useStaticQuery(graphql`
    query {
      allSanityImageAsset {
        edges {
          node { 
            id
            fluid { ...GatsbySanityImageFluid }
          }
        }
      }
    }`
  );
  const featured = allSanityImageAsset.edges.find(edge => edge.node.id === image).node;

  return (
    <Background Tag="div" fluid={featured.fluid}>
      <Project>
        <ProjectDetails>
          <Description>{small}</Description>
          <Name to={url} type='noIntl'>{big}</Name>
        </ProjectDetails>
        <ButtonOval href={url} type='noIntl' download={true}/>
      </Project>
    </Background>
  );
};

export default Download;
