import React from "react";
import Img from "gatsby-image";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";

import { socialMedia } from "@utils/variables";
import { formatDate } from "@utils/blogHelpers";

import CustomLink from "@components/link";

const Wrapper = tw(motion.div)`
  flex flex-wrap relative ml-40 mr-20 py-35
  sm:ml-20
  md:ml-0 md:justify-between
  lg:w-4/5 lg:mx-auto lg:left-45
  xl:w-2/3
`;

const Line = tw.div`absolute top-0 left-1/2 transform -translate-x-1/2 w-3/2 h-1 -ml-10 bg-border`;

const Image = tw(Img)`w-70 h-70 rounded-full`;

const Content = tw.div`flex sm:items-center`;

const Data = tw.div`pl-20 text-second md:text-md xl:pl-50`;

const Name = styled(CustomLink)`
  ${tw`font-pangramBold transition-colors duration-300`}

  &:hover {
    ${tw`text-orange`}
  }
`;

const SocialMedia = tw.div`
  w-full pl-90 
  sm:w-300 sm:mt-13
  md:flex md:justify-between md:w-400
`;

const SocialMediaText = tw.p`font-pangramBold text-sm text-second`;

const SocialMediaLinks = tw.div`flex justify-between w-150 md:w-180 md:text-md`;

const Link = styled.a`
  ${tw`text-orange transition-colors duration-300`}

  &:hover {
    ${tw`text-orangeHover`}
  }
`;

const Author = ({ width, author, date }: { width: Number, author: any, date: any }) => {
  const { t } = useTranslation();
  const authorUrl = `/blog/author/${author.slug.current}`;

  return (
    <Wrapper
      initial={ width >= 1024 && 'hidden'}
      animate={ width >= 1024 && 'visible' }
      transition={{
        y: { duration: .917, delay: 1.5, ease: [.88, .14, .12, .86] },
        opacity: { duration: .458, delay: 1.6, ease: 'linear' },
      }}
      variants={{
        'visible': { y: 0, opacity: 1 },
        'hidden': { y: 100, opacity: 0 }
      }}
    >
      <Line />
      <Content>
        <CustomLink to={authorUrl} type='noIntl'>
        { author.image && <Image fluid={author.image.asset.fluid} /> }
        </CustomLink>
        <Data>
          <Name to={authorUrl} type='noIntl'>
            {author.name}
          </Name>
          <p>{formatDate(date)}</p>
        </Data>
      </Content>
      <SocialMedia>
        <SocialMediaText>
          {t('Social media:Social media')}
        </SocialMediaText>
        <SocialMediaLinks>
          {socialMedia.map((name: string) => (
            <Link
              key={name}
              href={t(`Social media:${name} address`)}
              target='_blank'
              rel='noopener'
            >
              {t(`Social media:${name}`)}
            </Link>
          ))}
        </SocialMediaLinks>
      </SocialMedia>
    </Wrapper>
  );
};

export default Author;
