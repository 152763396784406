import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";

import { createBlogUrl } from "@utils/blogHelpers";

import CustomLink from "@components/link";

type CollectionTypes = {
  width: Number,
  index: number,
  isAuthorPage: boolean,
  item: any,
  active: boolean
};

const Wrapper = tw(motion.div)`relative lg:top-0 lg:opacity-0`;

const Item = styled(CustomLink)<{ active: boolean }>`
  ${tw`inline-block px-30 py-20 transition-colors duration-300 md:py-30`}
  ${({ active }) => active && tw`text-portfolioText`};

  &:hover {
    ${tw`text-portfolioText`}
  }
`;

const Collection = ({ width, index, isAuthorPage, item, active }: CollectionTypes) => {
  const itemTitle = isAuthorPage ? item.name : item.title.pl;
  const slug = isAuthorPage ? item.slug.current : item.slug.pl.current;
  const urlType = isAuthorPage ? 'author' : 'category';
  const itemUrl = createBlogUrl(slug, urlType);
  const isActive = active === itemTitle;

  return (
    <Wrapper 
      initial={width >= 1024 && 'hidden'} 
      animate={width >= 1024 && 'visible'} 
      transition={{ 
        y: { duration: .8, delay: 1.7 + .15 * index, ease: 'linear' }, 
        opacity: { duration: .6, delay: 1.4 + .15 * index, ease: [.88, .14, .12, .86] } 
      }}
      variants={{
        'visible': { y: 0, opacity: 1 }, 
        'hidden': { y: -10, opacity: 0 }
      }}
    >
      <Item key={index} to={itemUrl} type='noIntl' active={isActive}>
        {itemTitle}
      </Item>
    </Wrapper>
  )
};

export default Collection;
