import React from "react";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";

import PortableText from "@components/portableText";

const Wrapper = tw.div`
  relative ml-40 mr-20 mb-35 
  sm:ml-20 
  md:ml-0 md:mt-20 
  lg:mb-50 lg:ml-55
`;

const Description = styled(motion.div)`
  ${tw`
    w-full mb-10 text-md text-second leading-33 -tracking-016
    md:mb-60 md:mx-auto
    lg:order-2 lg:relative lg:top-120 lg:w-4/5 lg:text-xl lg:leading-40 lg:opacity-0
    xl:w-2/3 xl:pl-0
  `}

  ${down('md')} {
    transform: none !important;
  }

  span {
    white-space: nowrap;
  }
`;

const Intro = ({ width, intro }: { width: Number, intro: any }) => (
  <Wrapper>
    <Description
      initial={ width >= 1024 && 'hidden'}
      animate={ width >= 1024 && 'visible' }
      transition={{
        y: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] },
        opacity: { duration: .458, delay: 1, ease: 'linear' },
      }}
      variants={{ 'visible': { y: -120, opacity: 1, rotate: 0 } }}
    >
      <PortableText blocks={intro} />
    </Description>
  </Wrapper>
);

export default Intro;
