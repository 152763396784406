import React from "react";
import { useTranslation } from "react-i18next";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";

import PortableText from "@components/portableText";

const Wrapper = tw.div`relative flex flex-wrap ml-40 mr-20 mb-30 sm:ml-20 md:ml-0 md:mt-30 lg:mb-0`;

const Title = styled(motion.div)`
  ${tw`
    w-full mt-30 text-xl leading-33 -tracking-016
    md:mx-auto md:text-2xl md:leading-50
    lg:order-2 lg:relative lg:top-95 lg:w-4/5 lg:text-6xl lg:leading-64 lg:-tracking-028 lg:opacity-0
    xl:w-2/3
  `}
  
  ${down('md')} { 
    transform: none !important; 
  }

  span { 
    white-space: nowrap; 
  }
`;

const Character = tw(motion.div)`
  hidden order-1 w-1/12 mt-15 opacity-0 font-pangramBold text-lg text-second leading-32 -tracking-03
  lg:block
`;

const Header = ({ width, title }: { width: Number, title: any }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title
        initial={ width >= 1024 && 'hidden'}
        animate={ width >= 1024 && 'visible' }
        transition={{
          y: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] },
          opacity: { duration: .458, delay: .65, ease: 'linear' },
          rotate: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] }
        }}
        variants={{
          'visible': { y: -120, opacity: 1, rotate: 0 },
          'hidden': { rotate: -8 }
        }}
      >
        <PortableText blocks={title} />
      </Title>
      <Character
        animate={ width >= 1024 && { opacity: 1 }}
        transition={{ duration: .6, delay: .8, ease: [.88, .14, .12, .86] }}
      >
        {t('Blog:CharacterPost')}
      </Character>
    </Wrapper>
  );
};

export default Header;
