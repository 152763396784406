import React, { useState, useLayoutEffect } from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import tw from "twin.macro";

import { pagesRoutes } from "@utils/variables";

import LinkPrimary from "@components/links/primary";
import ArrowPrimary from "@components/arrows/primary";

const Wrapper = tw.div`relative flex mb-50 bg-footerDark sm:rounded-lg lg:mt-50 lg:mb-75 lg:mx-15`;

const Column = tw.div`relative pl-40 py-50 sm:w-1/2 sm:pl-100 lg:pt-85 lg:pb-80 lg:pl-180`;

const Header = tw.div`
  max-w-300 mb-15 text-xl text-white leading-40 -tracking-016
  lg:max-w-400 lg:text-4xl lg:leading-64 lg:-tracking-028
`;

const Paragraph = tw.p`
  max-w-220 mb-50 text-sm text-white leading-22 -tracking-021
  lg:max-w-300 lg:text-md lg:leading-35
`;

const Arrow = tw.div`
  absolute -top-50 right-0 sm:top-auto 
  sm:-bottom-50 sm:-right-30 
  xl:bottom-110 xl:right-55
`;

const Illustration = tw(motion.div)`relative`;

const Image = tw.div`
  absolute z-10 -bottom-80 right-20 w-1/2 max-w-520
  sm:-bottom-25 sm:right-50 sm:w-1/2
  lg:right-auto lg:left-1/2
`;

const Offer = ({ scroll, width }: { scroll: any, width: number }) => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "offer/illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const OfferRef = React.useRef();

  const [sectionStart, setSectionStart] = useState(0);
  const [sectionEnd, setSectionEnd] = useState(.5);

  const illustrationOpacity = useTransform(scroll, [sectionStart + 200, sectionStart + 450], [0, 1]);
  const illustrationTop = useTransform(scroll, [sectionStart + 200, sectionStart + 450], [120, 0]);
  const illustrationLeft = useTransform(scroll, [sectionStart + 200, sectionStart + 450], [-100, 0]);

  useLayoutEffect(() => {
    if (!OfferRef.current) return;
    
    const offsetStart = OfferRef.current.offsetTop - window.innerHeight;
    const offsetEnd = offsetStart + OfferRef.current.clientHeight;

    setSectionStart(offsetStart);
    setSectionEnd(offsetEnd);
  }, []);

  return (
    <Wrapper ref={OfferRef}>
      <Column>
        <Header>
          Twoja firma musi się komunikować,
        </Header>
        <Paragraph>
          ale słowa więzną Ci w gardle? Sprawdź, jak możemy Ci pomóc.
        </Paragraph>
        <LinkPrimary 
          className={'mx-auto lg:mx-0'} 
          text={'Zobacz ofertę'} 
          path={pagesRoutes.offer}
        />
      </Column>
      <Image>
        <Illustration
          style={ width >= 1024 && {
            opacity: illustrationOpacity,
            top: illustrationTop,
            left: illustrationLeft
          }}
        >
          <Img fluid={data.illustration.childImageSharp.fluid} />
        </Illustration>
      </Image>
      <Arrow><ArrowPrimary /></Arrow>
    </Wrapper>
  );
};

export default Offer;
