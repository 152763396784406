import React from "react";
import tw from "twin.macro";

const Labels = tw.div`flex justify-between pt-5 text-primary md:pt-10`;

const Label = tw.span`flex-1 text-center`;

const ChartLabels = ({ data }: { data: any }) => (
  <Labels>
    {data.map((item, i) => (
      <Label key={i}>{item}</Label>
    ))}
  </Labels>
);

export default ChartLabels;
