import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";

import CustomLink from "@components/link";
import PortableText from "@components/portableText";

const Wrapper = tw.div`my-25 sm:my-40 lg:my-50`;

const Header = tw.p`mb-15 px-40 text-primary text-md text-center sm:mb-25 sm:px-0 sm:text-xl`;

const Box = styled.div`
  ${tw`border border-solid border-border sm:flex sm:items-stretch lg:items-center lg:h-230 lg:overflow-hidden`}
  
  ${up('sm')} { 
    border-radius: 8px;
  }
`;

const ImageBox = styled(CustomLink)`
  ${tw`block relative min-h-full overflow-hidden sm:w-1/2`}

  ${up('sm')} {
    border-radius: 8px 0 0 8px;
  }
`;

const Image = styled(Img)`
  ${up('sm')} img {
    ${tw`sm:absolute sm:top-0 sm:left-0`}
  }
`;

const Preview = tw.div`box-border py-15 pl-40 pr-20 sm:w-1/2 sm:p-15 lg:pl-40`;

const Title = styled(CustomLink)`
  ${tw`text-primary transition-colors duration-300 sm:text-md lg:leading-27`}

  &:hover {
    ${tw`text-portfolioText`}
  }
`;

const Text = tw.div`mt-10 text-xs sm:text-sm lg:mt-15 lg:leading-22`;

const Post = ({ id }: { id: String }) => {
  const { allSanityPost } = useStaticQuery(graphql`
    query {
      allSanityPost {
        edges {
          node {
            id
            slug { pl { current }}
            title {_rawPl}
            preview { _rawPl }
            previewImage { asset { fluid { ...GatsbySanityImageFluid }}}
          }
        }
      }
    }
  `);
  const post = allSanityPost.edges.find(edge => edge.node.id === id).node;
  const postUrl = `/blog/${post.slug.pl.current}`;

  return (
    <Wrapper>
      <Header>To może być dla Ciebie interesujące</Header>
      <Box>
        <ImageBox to={postUrl} type='noIntl'>
          <Image fluid={post.previewImage.asset.fluid} />
        </ImageBox>
        <Preview>
          <Title to={postUrl} type='noIntl'>
            <PortableText blocks={post.title._rawPl} />
          </Title>
          <Text>
            <PortableText blocks={post.preview._rawPl} />
          </Text>
        </Preview>
      </Box>
    </Wrapper>
  );
};

export default Post;
