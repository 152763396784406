import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { handleColor } from "@utils/blogHelpers";

const Body = tw.tbody`flex justify-between items-stretch w-full h-full`;

const Row = tw.tr`relative flex flex-col flex-1 justify-end px-10 sm:px-20 md:px-30 lg:px-20`;

const Cell = styled.td<{ size: number, color: number, isStacked: boolean, isFirst: boolean, isLast: boolean } >`
  ${tw`relative flex justify-center items-start w-full`}

  height: ${({ size }) => size ? `${size}%` : 0};
  background-color: ${({ color }) => handleColor(color)};
  border-top-left-radius: ${({ isFirst }) => isFirst && '30px'};
  border-top-right-radius: ${({ isFirst }) => isFirst && '30px'};
  border-bottom-left-radius: ${({ isLast }) => isLast && '30px'};
  border-bottom-right-radius: ${({ isLast }) => isLast && '30px'};
  ${({ isStacked }) => isStacked ? tw`flex-none` : tw`flex-1`};
  ${({ size }) => !size && tw`hidden`};
`;

const ChartColumn = ({ data, params, stacked }: { data: object, params: object, stacked: boolean }) => (
  <Body>
    {data.values.map((value, index) => {
      const validValues = value.filter(item => parseInt(item) !== 0);

      return (
        <Row key={index}>
          {value.map((item, i) => {
            const size = 100 * parseInt(item) / params.scale;
            const order = validValues.indexOf(item);
            
            return (
              <Cell 
                key={i} 
                size={size} 
                color={i}
                isStacked={stacked} 
                isFirst={!order}
                isLast={order === validValues.length - 1}
              />
            )
          })}
        </Row>
      )
    })}
  </Body>
);

export default ChartColumn;
