import React, { Fragment } from "react";
import tw from "twin.macro";
import styled from "styled-components";

const TableWrapper = tw.div`
  my-25 pl-40 pr-20 overflow-x-auto
  sm:pl-0 sm:pr-10 sm:overflow-x-visible
  lg:pr-0
`;

const TableContent = tw.table`w-full`;

const Spacing = tw.tr`h-5 lg:h-8`;

const Row = styled.tr<{ top: boolean, marked: boolean }>`
  ${tw`border border-solid border-border rounded-md`}

  ${({ top }) => top && tw`border-none opacity-75`}
  ${({ marked }) => marked && tw`border-2 border-portfolioText`}
`;

const EmptyCell = styled.td<{ marked: boolean }>`
  ${({ marked }) => marked && tw`border-l-2 border-r-2 border-yellow`}
`;

const Cell = styled(EmptyCell)<{ top: boolean, bottom: boolean, left: boolean, marked: boolean }>`
  ${tw`p-12 text-sm lg:text-base lg:px-35`}

  ${({ top, marked }) => top && marked && tw`border-t-2`}
  ${({ bottom, marked }) => bottom && marked && tw`border-b-2`}
  ${({ left }) => left && tw`opacity-75`}
`;

const Table = ({ data }: { data: any }) => {
  const rows = data.content.rows;
  const columnsCount = rows[0].cells.length;

  return (
    <TableWrapper>
      <TableContent>
        <tbody>
          {rows.map((row, index) => {
            const firstRow = !index;
            const secondRow = index === 1;
            const lastRow = index === rows.length - 1;

            return (
              <Fragment key={index}>
                <Row top={firstRow} marked={index === data.row}>
                  {row.cells.map((cell, i) => {
                    const firstColumn = !i;
                    const marked = !firstRow && i === data.column - 1;

                    return (
                      <Cell key={i} top={secondRow} bottom={lastRow} left={firstColumn} marked={marked}>
                        {cell}
                      </Cell>
                    )
                  })}
                </Row>
                { !firstRow && !lastRow && ( 
                  <Spacing>
                    {[...Array(columnsCount).keys()].map(column => {
                      const marked = column === data.column - 1;
                      return <EmptyCell key={column} marked={marked}></EmptyCell>
                    })}
                  </Spacing>
                )}
              </Fragment>
            )
          })}
        </tbody>
      </TableContent>
    </TableWrapper>
  );
};

export default Table;
