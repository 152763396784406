import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

const Wrapper = tw.div`relative my-25 w-full h-full md:my-40`;

const Frame = tw.div`relative`;

const Iframe = tw.iframe`absolute top-0 left-0 w-full h-full z-1`;

const Ratio = tw(Img)`block w-full h-auto`;

const Cover = styled.div<{ clicked: boolean, hidden: boolean }>`
  ${tw`absolute top-0 left-0 z-3 w-full h-full transition-all duration-300`}
  ${({ clicked }) => clicked && tw`opacity-0`};
  ${({ hidden }) => hidden && tw`hidden`};
`;

const CoverImg = tw(Img)`absolute! top-0 left-0 z-1 w-full h-full z-2`;

const Button = styled.button`
  ${tw`
    flex items-center justify-center absolute top-1/2 left-1/2 z-2 transform -translate-x-1/2 -translate-y-1/2
    w-90 h-90 bg-white hover:bg-orange rounded-full border-orange transition-all duration-300
  `}
  border-width: 5px;

  &:hover svg {
    border-left: 1px solid white;

    polygon {
      stroke: #ffffff;
    }
  }
`;

const Arrow = styled.svg`
  ${tw`w-12 h-18 ml-5 transition-colors duration-300`}
  border-left: 1px solid #182741;
`;

const Polygon = styled.polygon`
  ${tw`stroke-2 transition-colors duration-300`}
  stroke: #182741;
  fill: transparent;
`;

const Youtube = ({ cover, id }: { cover: any, id: String }) => {
  const { allSanityImageAsset, illustration } = useStaticQuery(graphql`
    query {
      allSanityImageAsset {
        edges {
          node { 
            id
            fluid { ...GatsbySanityImageFluid }
          }
        }
      }
      illustration: file(relativePath: { eq: "placeholder-16x9.png" }) {
        childImageSharp {
          fluid { ...GatsbyImageSharpFluid }
        }
      }
    }`
  );
  const coverImg = cover ? allSanityImageAsset.edges.find(edge => edge.node.id === cover.asset._ref).node : null;

  const [isButtonClicked, setButtonClicked] = useState(false);
  const [isCoverHidden, setCoverHidden] = useState(false);

  
  const handleClick = () => {
    setButtonClicked(true);
    setTimeout(() => setCoverHidden(true), 300);
  };

  return (
    <Wrapper>
      <Frame>
        <Iframe src={`https://www.youtube.com/embed/${id}`} frameborder="0" allowfullscreen />
        <Ratio fluid={illustration.childImageSharp.fluid} />
      </Frame>
      { cover && (
        <Cover clicked={isButtonClicked} hidden={isCoverHidden}>
          <CoverImg fluid={coverImg.fluid} />
          <Button onClick={() => handleClick()}>
            <Arrow><Polygon points="0,0 9,9 0,18"/></Arrow>
          </Button>
        </Cover>
      )}
    </Wrapper>
  );
};

export default Youtube;

