import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Grid = tw.div`relative -top-150 h-150 -mb-150 md:-top-300 md:h-300 md:-mb-300`;

const Line = styled.span<{ height: number }>`
  ${tw`absolute left-0 -z-1 w-full h-1 bg-border`}
  bottom: ${({ height }) => height ? `${height}%` : 0};
`;

const ChartGrid = ({ scale, grad }: { scale: number, grad: number }) => {
  const lines = Math.ceil(scale / grad);

  return (
    <Grid>
      {[...Array(lines).keys()].map(line => 
        <Line key={line} height={100 * (line / lines)} />
      )}
      <Line height={100} />
    </Grid>
  )
};

export default ChartGrid;
