import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { handleColor } from "@utils/blogHelpers";

const Legend = tw.div`pb-25 md:flex md:flex-wrap md:pl-30 md:pb-40 lg:pb-50`;

const Def = tw.div`flex items-center text-sm md:mr-30 md:text-base`;

const Dot = styled.span<{index: number}>`
  ${tw`h-6 w-6 mr-10 rounded-lg`}
  background-color: ${({ index }) => handleColor(index)};
`;

const ChartLegend = ({ data }: { data: any }) => (
  <Legend>
    {data.map((item, i) => (
      <Def key={i}>
        <Dot index={i}/>
        {item}
      </Def>
    ))}
  </Legend>
);

export default ChartLegend;
