import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import CustomLink from "@components/link";
import PortableText from "@components/portableText";

const Wrapper = tw.div`
  mb-40 
  sm:w-1/2 sm:px-20
  md:px-0 md:pr-40
  lg:w-1/3 lg:pr-30
`;

const Title = styled(CustomLink)`
  ${tw`
    block mt-20 mb-15 px-40 text-md leading-30 -tracking-016 transition-colors duration-300
    sm:px-0
    lg:relative lg:ml-0 lg:mb-10 lg:text-xl lg:leading-35 lg:-tracking-024
  `}

  &:hover {
    ${tw`text-portfolioText`}
  }
`;

const Preview = tw.div`
  px-40 text-sm text-second
  sm:px-0
  lg:pr-50 lg:text-base
`;

const Item = ({ data }: { data: any }) => {
  const postUrl = `/blog/${data.slug.pl.current}`;

  return (
    <Wrapper>
      <CustomLink to={postUrl} type='noIntl'>
        <Img fluid={data.previewImage.asset.fluid} />
      </CustomLink>
      <Title to={postUrl} type='noIntl'>
        <PortableText blocks={data.title._rawPl} />
      </Title>
      <Preview>
        <PortableText blocks={data.preview._rawPl} />
      </Preview>
    </Wrapper>
  )
};

export default Item;
