import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { handleColor, createPolygon } from "@utils/blogHelpers";

const Body = tw.tbody`flex justify-between items-stretch w-full h-full px-20 sm:px-30`;

const Row = tw.tr`relative flex flex-1 justify-start items-end`;

const Cell = styled.td<{ start: number, size: number, width: number, color: number, isStacked: boolean } >`
  ${tw`absolute top-0 right-0 bottom-0 left-0 z-0 flex flex-col items-end justify-end w-full h-full`}

  ${({ isStacked }) => isStacked ? tw`flex-none` : tw`flex-1`};

  &::before {
    ${tw`absolute top-0 bottom-0 left-0 right-0 -z-1 border border-border`}
    content: '';
    background-color: ${({ color }) => handleColor(color)};
    clip-path: ${({ size, start, width }) => createPolygon(size, start, width)};
  }

  &::after {
    ${tw`w-full`}
    content: '';
    height: ${({ size }) => size ? `${size}%` : 0};
  }
`;

const ChartLine = ({ data, params, stacked }: { data: object, params: object, stacked: boolean }) => (
  <Body>
    {data.values.map((value, index) => index > 0 && (
      <Row key={index}>
        {value.map((item, i) => {
          const formatValue = value => 100 - (100 * parseInt(value) / params.scale);
          const start = index && formatValue(data.values[index - 1][i]);
          const size = formatValue(item);
          const diff = Math.abs(start - size);
          const width = diff < 30 ? 2: diff < 70 ? 3 : 6;

          return (
            <Cell 
              key={i}
              start={start}
              size={size}
              width={width}
              color={i}
              isStacked={stacked} 
            />
          )
        })}
      </Row>
    ))}
  </Body>
);

export default ChartLine;
